$padding-horizontal: 30px;
$padding-vertical: 15px;

.PageWizardView {
  .save {
    margin-left: 20px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .main {
    display: flex;
    flex-direction: column;
    padding: $padding-vertical * 1.25 $padding-horizontal * 1.25;
    flex: 1;
    overflow-y: scroll;
    max-height: 100%;
    position: relative;
  }
}