.CustomFieldsView {
  .fieldsContainer {
    display: flex;
    flex-direction: column;
    width: 350px;
  }

  .field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid _grayEEE_;
    padding: 10px 0;
    background: _backgroundColor_;
    div {
      display: flex;
      flex-direction: row;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  .fieldActionButtons {
    display: flex;
    align-items: center;
  }

  .modalHeader {
    padding-bottom: 30px;
  }
}

body.is-mobile .CustomFieldsView {
  display: flex;
  flex-direction: column;
  flex: 1;
  .fieldsContainer {
    width: 100%;
    font-size: 1.1rem;
  }
}

body.is-touch .CustomFieldsView {
  .Button {
    height: 44px;
  }
}