.PageWizardEventAvailabilityView {
  min-width: 0; /* Fixes issue where page was extending outside bounds of its container. */
  width: 100%;

  .slugContainer {
    flex-direction: row;
    display: flex;
    align-items: center;

    .slugLabel {
      color: _gray666_;
      font-size: 15px;
      margin-right: 5px;
    }

    .slugField {
      width: 200px;
    }
  }
  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    max-width: 480px;
  }

  .textInputContainer {
    max-width: 100%;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }

  .timeFieldSection {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 25px;
      margin-right: 10px;
    }
  }

  .timeField {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    label {
      white-space: nowrap;
    }
  }

  .timeFieldSelectContainer {
    display: flex;
    flex-shrink: 0;
  }
}
