.participantsFieldInputContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
}

.participantsFieldParticipants {
    display: grid;
    grid-auto-flow: row;
    gap: 2rem;
    margin: 2rem 1rem;

    .participantsFieldParticipant {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 0.5rem;

        button {
            display: grid;
            align-items: center;
            border: 0;
            background-color: transparent;
            color: _tintColor_;
            cursor: pointer;
            &:hover {
                opacity: 0.3;
            }
            &:disabled {
                opacity: 0.3;
                cursor: auto;
            }
        }

        .participantsFieldParticipant {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            .name {
                font-weight: 500;
                color: black;
            }
            .name + .email {
                color: grey;
            }
            .tag {
                font-weight: normal;
                color: grey;
                border: 1px solid grey;
                border-radius: 0.25rem;
                padding: 0.15rem 0.5rem;
            }
        }
    }
}