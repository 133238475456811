.DropdownValuesPicker {
  .addDropdownOption {
    display: flex;
    flex-direction: row;
  }

  .dropdownOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 0;
    max-width: 190px;
  }
}
