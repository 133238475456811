.BookingMainView {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 900px;
  margin: auto;

  .centeredMessage {
    margin: 9vh auto;
    max-width: 400px;
    min-height: 200px;
    line-height: 1.6rem;
    font-size: 1.1rem;
  }

  .addGuestsButton {
    cursor: pointer;
    text-align: left;
    margin-bottom: 10px;
    color: #777;
    background: none;
    border: none;
    display: block;
    font-size: 1rem;
    padding: 0;
    &:hover {
      color: _tintColor_;
    }
  }

  .TimezoneSelectView {
    align-self: auto;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 1rem;
    &:focus-visible,
    &:focus {
      outline: 2px auto Highlight;
      outline: 2px auto -webkit-focus-ring-color;
    }
    .menu {
      bottom: 38px;
      left: 0px;
    }
    button {
      background-color: transparent;
      border: none;
      border-radius: 16px;
      box-shadow: none;
    }
  }

  /** so select label is only accessible to users of assistive technology **/
  label[for="locale"] {
    align-items: center;
    display: flex;
    &:focus-visible,
    &:focus {
      outline: 2px auto Highlight;
      outline: 2px solid -webkit-focus-ring-color;
    }
  }

  .sr-only {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
  }

  svg.locale-icon {
    margin-right: -30px;
    z-index: -1;
  }

  select.LanguageSelectView {
    appearance: none;
    background: none;
    border-color: transparent;
    border-radius: 16px;
    box-shadow: none;
    padding: 5px 5px 5px 32px;
    font-size: 1rem;
    &:focus,
    &:focus-visible {
      outline: 2px auto Highlight;
      outline: 2px solid -webkit-focus-ring-color;
    }
  }

  .TimezoneSelectView .menu ul {
    height: 400px;
  }

  .spinner-container {
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    animation: fadein 300ms;
    animation-fill-mode: forwards;
    animation-delay: 900ms;

    .spinner-slow-message {
      color: #666;
      opacity: 0;
      padding-top: 25px;
      animation: fadein 300ms;
      animation-fill-mode: forwards;
      animation-delay: 4s;
    }
  }

  .state-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0;
    max-width: 600px;
    text-align: center;
    margin: auto;
    flex: 1.4;

    h3 {
      margin: 10px 30px;
    }
    .Button {
      margin-top: 20px;
      align-self: center;
    }
  }

  .top-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 600px;
    margin-top: 20px;
    width: 100%;

    .logo {
      width: 150px;
      height: 80px;
      background-color: white;
      display: flex;
      padding: 15px 0;
      align-items: center;
      img {
        max-width: 100%;
        max-height: 80px;
      }
    }

    .header {
      margin: auto 20px;
    }

    .logo-spacer {
      width: 150px;
    }
  }

  .poweredBy {
    width: 100%;
    color: #aaa;
    font-size: 0.8rem;
    text-align: center;
    padding-top: 70px;
    padding-bottom: 30px;
    img {
      width: 40px;
    }
  }

  .footer {
    width: 100%;
    height: 51px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    opacity: 1;
    transform: translateY(0);
    transition: opacity 150ms linear, transform 150ms linear;
  }

  .footer--spacer {
    flex: 1;
  }

  .footer--right {
    display: inline-flex;
  }

  .covered .footer {
    opacity: 0;
    transform: translateY(-10px);
  }

  .view-type {
    align-items: center;
    background: none;
    border: none;
    border-radius: 16px;
    box-shadow: none;
    cursor: default;
    display: flex;
    height: 32px;
    padding: 0 13px;
    position: relative;
    user-select: none;
    background-color: transparent;
    border: 0;

    svg {
      margin-right: 10px;
    }
  }

  .header {
    margin: 0;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    flex-shrink: 0;
    flex: 1;
  }
}

.FullScreenError {
  margin: auto;
  padding-top: 30vh;
  line-height: 1.5rem;
  max-width: 300px;
  text-align: center;
  svg {
    display: block;
    margin: auto;
  }
}

.ShadowedContent {
  margin: auto;
  width: 100%;
  max-width: 900px;
  box-sizing: border-box;
  text-align: left;
  border: 1px solid transparent;
  border-radius: 6px;
  box-shadow: none;
  position: relative;
  animation: shadowin 300ms;
  animation-delay: 200ms;
  animation-fill-mode: forwards;
  pointer-events: none;

  &.loaded {
    animation: shadowin 0ms;
    animation-fill-mode: forwards;
    pointer-events: initial;
    .spinner-container {
      opacity: 0;
      animation: fadeout 250ms;
      animation-fill-mode: forwards;
    }
    .CalendarWeekView {
      opacity: 1;
      animation: fadein 250ms;
      animation-fill-mode: forwards;
    }
    .col-month {
      animation: fadein 250ms;
      animation-fill-mode: forwards;
    }
    .col-slot-list {
      animation: fadein 250ms;
      animation-fill-mode: forwards;
    }
  }
}

@media screen and (max-width: 490px) {
  .footer {
    margin-top: 1em;
    flex-direction: column;
  }
}
@media screen and (max-width: 690px) {
  .BookingMainView {
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 20px;
    width: 90vw;

    .top-bar {
      flex-direction: column;
      min-width: initial;
    }

    .logo {
      justify-content: center;
    }
    .header {
      height: initial;
      margin-bottom: 15px;
      font-size: 1.7em;
    }
  }

  .SlotConfirmView,
  .SlotCancelView,
  .HostApproveBookingView {
    flex-direction: column;
    position: relative;
    animation: none;

    .divider {
      border: none;
    }
    .left-panel {
      padding-top: 40px;
      padding-bottom: 0;
    }
    .BookingForm {
      width: 100%;
      form {
        padding: 30px 30px 0
      }
    }
  }
  .covered {
    .CalendarMonthView {
      display: none;
    }
    .CalendarWeekView {
      display: none;
    }
  }
  .CalendarMonthView {
    flex-direction: column;
    width: auto;
    margin: auto;
    padding: 14px;
    min-width: 100px;
    max-height: initial;

    .col-slot-list {
      margin-left: 0;
      padding-top: 10px;
      padding-left: 0;
      border-top: 1px solid #eee;
      border-left: none;

      .empty-state {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .slots-wrap {
        height: initial;
        max-height: initial;
      }
      .bottom-fade {
        display: none;
      }
    }
    .col-month {
      margin-bottom: 10px;
      .month-grid-row {
        .month-grid-day {
          width: 8vw;
          height: 8vw;
          line-height: 8vw;
          margin: 4px;
          &:before {
            width: 8vw;
            height: 8vw;
          }
          &::after {
            width: 8vw;
            height: 8vw;
          }
        }
      }
    }
  }
}
.reschedule-header {
  &::before {
    content: " ";
    background: _tintColor_;
    opacity: 0.15;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  color: black;
  width: 100%;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 10px;
}
