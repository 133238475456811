ul.TokenizedInput {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 4px 2px;
  border-bottom: 1px solid #ddd;
  border-radius: 3px;
  background-color: white;
  font-size: 1.1rem;
  font-weight: 400;
  cursor: text;
  position: relative;
  margin: 0;

  &.readonly {
    .token .delete { display: none; }
    li.tokenInput { display: none; }
  }
  &:focus-within {
    border-bottom: 1px solid _tintColor_;
  }
  .token {
    display: inline-flex;
    background-color: #f5f7f8;
    border: 1px solid #c1c7cf;
    border-radius: 3px;
    color: _black_;
    padding: 1px 8px;
    margin-right: 6px;
    margin-top: 2px;
    margin-bottom: 2px;
    align-items: center;

    .delete {
      font-size: 1rem;
      margin-right: 5px;
      cursor: pointer;
      color: #aaa;
      &:hover {
        color: _black_;
      }
    }

    .label {
      line-height: 20px;
    }

    &.invalid {
      border-color: #d85454;
      background-color: #f5e9e9;
    }
  }

  li.tokenInput {
    display: inline-flex;

    input {
      padding-right: 20px;
      width: 100%;
      box-sizing: border-box;
      font-size: 1.1rem;
      border: none;
      outline: 0;
      margin-top: 6px;
      margin-bottom: 6px;
      padding: 0;
      &:focus {
        border: none;
      }
    }
  }
}
