.Sheet {
  background: _backgroundColor_;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  animation: SlideInTransition 200ms linear forwards;

  &.dismissing {
    animation: SlideOutTransition 200ms linear forwards;
  }

  .sheet-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 15px 30px;
    overflow-y: auto;
  }

  .back-arrow {
    color: _tintColor_;
    background: transparent;
    border: none;

    justify-content: flex-start;
    margin-left: 10px;
    margin-top: 21px;
    font-size: 1.1rem;
    font-weight: 500;
    align-items: center;
    display: inline-flex;
    cursor: default;
    padding: 5px;

    svg {
      transform: translate3d(0, 0, 0);
      transition: transform 150ms linear;
    }
    &:hover {
      opacity: 0.9;
      svg {
        transform: translate3d(-4px, 0, 0);
      }
    }
  }

  .sheet-footer {
    display: flex;
    flex-direction: row;
    padding: 0 30px;
    padding-bottom: 15px;
    .Button {
      margin-right: 15px;
    }
  }
}

@keyframes SlideInTransition {
  from {
    opacity: 0.8;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes SlideOutTransition {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
}
