.EventInfoView {
  min-width: 0; /* Fixes issue where page was extending outside bounds of its container. */
  width: 100%;

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    max-width: 480px;

    &[name='attendee_capacity'] {
      // for placeholder "capacity" + 2 padding
      width: 10ch;
    }
  }

  .formErrors {
    color: red;
  }

  .textInputContainer {
    max-width: 100%;
    margin-top: 15px;
  }

  .timeFieldSection {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 25px;
      margin-right: 10px;
    }
  }

  .timeField {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    label {
      white-space: nowrap;
    }
  }

  .timeFieldSelectContainer {
    display: flex;
    flex-shrink: 0;
  }

  .cancellationContainer {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

    textarea {
      min-height: 2rem;
      max-width: 480px;
    }
  }

  .attendeeCapacityContainer {
    margin-top: 25px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .participantFieldSection {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
}

body.is-mobile .EventInfoView {
  input {
    width: 100%;
    max-width: none;
  }

  label {
    font-size: 1.1rem;
  }

  .textInputContainer {
    max-width: none;
  }

  .timeFieldSection {
    & > div:last-child {
      margin-right: 0px;
    }
  }

  .cancellationContainer {
    textarea {
      max-width: none;
    }
  }
}
