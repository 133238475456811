.SVGButton {
  color: _black_;
  padding: 5px 5px;
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: none;
  background: transparent;

  &:hover {
    background-color: _grayEEE_;
  }

  &.tinted {
    color: _tintColor_;
    &:hover {
      background: transparent;
      opacity: 0.6;
    }
  }

  &.disabled {
    color: _grayCCC_;
    cursor: default;

    &:hover,
    &:active {
      background: transparent;
      opacity: 1;
    }
  }
}
