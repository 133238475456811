.CalendarMonthView {
  display: flex;
  padding: 27px;
  box-shadow: none;
  border: 1px solid transparent;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    padding-left: 12px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 0;
  }

  .col-month {
    flex: 1;
    position: relative;
    opacity: 0;

    .month-switcher {
      display: flex;
      margin-bottom: 18px;
      padding-left: 12px;
      align-items: center;
      user-select: none;

      h2 {
        margin: 0;
        flex: 1;
      }
      .SVGButton {
        width: 30px;
      }
    }
    .month-grid-header {
      display: flex;
      margin-bottom: 8px;
      & > div {
        flex: 1;
        color: #333;
        text-transform: uppercase;
        text-align: center;
        font-size: 0.9rem;
      }
    }
    .month-grid-row {
      display: flex;
      user-select: none;
      justify-content: space-between;

      .month-grid-day-container {
        text-align: center;
        flex: 1;
      }

      .month-grid-day {
        width: 4.5vw;
        height: 4.5vw;
        max-width: 50px;
        max-height: 50px;
        margin: 4px;
        text-align: center;
        font-size: 1.15rem;
        font-weight: 600;
        position: relative;
        color: #888;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: top;
        background: none;
        border: none;
        outline: none;

        &::before,
        &::after {
          content: ' ';
          position: absolute;
          border-radius: 25px;
          display: block;
          opacity: 0;
          top: 0;
          left: 0;
          width: 4.5vw;
          height: 4.5vw;
          max-width: 50px;
          max-height: 50px;
          box-sizing: border-box;
        }

        &.populated {
          color: _tintColor_;

          &::before {
            opacity: 0.1;
            background-color: _tintColor_;
          }
          &:hover {
            &::before {
              opacity: 0.15;
            }
          }
        }
        &:focus.populated {
          &::after {
            opacity: 1;
            border: 1px dashed _tintColor_;
          }
        }
        &:active.populated,
        &.populated.selected {
          &::after {
            opacity: 1;
            border: 1px solid _tintColor_;
          }
        }

        &.populated.not-free {
          color: #343434;

          &::before {
            opacity: 0.1;
            background-color: #777;
          }
          &:hover {
            &::before {
              opacity: 0.15;
            }
          }
        }
        &.populated.not-free.selected {
          &::after {
            opacity: 1;
            border: 1px solid #343434;
          }
        }
      }
    }
  }

  .col-month {
    margin-bottom: 30px;
    .no-slots-available {
      position: absolute;
      background: rgba(255, 255, 255, 0.7);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 4;
      div {
        background: rgb(255, 255, 255);
        padding: 24px;
      }
    }
  }

  .col-slot-list {
    flex: 1;
    margin-left: 27px;
    position: relative;
    padding-left: 27px;
    border-left: 1px solid #eee;
    opacity: 0;
    display: flex;
    flex-direction: column;

    .empty-state {
      text-align: center;
      width: 100%;
    }

    .slots-wrap {
      height: 75vh;
      max-height: 350px;
      // pad the scrollbar a bit
      padding-right: 10px;
      margin-right: -10px;
      position: relative;
      .slots {
        height: 100%;
        width: 100%;
        overflow: auto;
        margin-bottom: 30px;
      }
    }
    .bottom-fade {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 30px;
      transition: opacity 150ms linear;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      pointer-events: none;
    }

    .slot {
      line-height: 40px;
      cursor: default;
      margin-bottom: 6px;
      display: flex;

      .bordered {
        flex: 1;
        background: transparent;
        border: 1px solid #888;
        border-radius: 5px;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 40px;
        color: #333;
      }
      &:hover .bordered {
        color: _tintColor_;
        border: 1px solid _tintColor_;
      }
      button.confirm {
        color: white;
        background-color: #333;
        background-color: _tintColor_;
        font-size: 1.1rem;
        font-weight: 500;
        overflow: hidden;
        width: 50%;
        animation: expandwidth 250ms ease-out forwards;
        border-radius: 5px;
        border: none;
        margin-left: 10px;
      }
    }
  }
  .autoschedule {
    text-align: center;
    bottom: 0;
    right: 0;
    left: 27px;

    p {
      max-width: 280px;
      line-height: 1.4rem;
      margin: auto;
      opacity: 0.8;
    }
    .autoschedule-note {
      color: #888;
      font-size: 0.8rem;
      a {
        color: #888;
      }
    }
  }

  .autoschedule-or {
    width: 100%;
    border-top: 1px solid #ccc;
    position: relative;
    & > div {
      position: absolute;
      background: white;
      padding: 3px 8px;
      color: #999;
      top: -13px;
      left: 50%;
      width: 30px;
      margin-left: -15px;
      text-align: center;
      box-sizing: border-box;
    }
  }
}

@keyframes expandwidth {
  from {
    width: 0;
  }
  to {
    width: 50%;
  }
}

@keyframes shadowin {
  from {
    border: 1px solid rgba(214, 214, 214, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
  }
  to {
    border: 1px solid rgba(214, 214, 214, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  }
}

button.Switch {
  border: none;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.3);
  width: 45px;
  height: 24px;
  position: relative;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.3);
  outline: 0;
  padding: 0;

  &.active,
  &:focus {
    border: 1px solid #3a1d3a;
    box-sizing: content-box;
  }
  .Handle {
    width: 22px;
    height: 22px;
    display: inline-block;
    position: absolute;
    border-radius: 11px;
    left: 1px;
    top: 1px;
    background: linear-gradient(to bottom, #f6f6f6 0%, #cfcfcf 100%);
    border: 1px solid white;
    box-sizing: border-box;
    transition: left 200ms linear;
  }
  &.on > .Handle {
    left: 22px;
  }
}
