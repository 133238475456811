.BookingFlowView {
  .redirect-url-field {
    width: 480px;
  }
}

body.is-mobile .BookingFlowView {
  display: flex;
  flex-direction: column;
  flex: 1;
  .redirect-url-field {
    width: 100%;
  }
}