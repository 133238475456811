.ImageInput {
  width: 100%;
  height: 58px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0.25rem;
  position: relative;
  flex-direction: column;

  &.populated {
    border: 1px dashed transparent;
    padding: 0;
    justify-content: left;
    width: 228px;
    &:hover {
      border: 1px dashed rgba(0, 0, 0, 0.4);
      background: rgba(0, 0, 0, 0.03);
    }
  }

  .progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: _tintColor_;
    transition: width 200ms linear;
  }
  img {
    max-width: 300px;
    max-height: 66px;
  }
}
