.SlotConfirmView {
  animation: sheetin 200ms forwards;

  .right-panel {
    overflow: auto;
    max-height: 100%;
  }
}

.SlotConfirmView,
.SlotCancelView,
.HostApproveBookingView {
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  position: absolute;
  z-index: 200;
  background: white;
  border-radius: 6px;
  height: 100%;
  width: 100%;

  .back {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.1rem;
    padding: 10px;
    color: _tintColor_;
    svg {
      padding-right: 10px;
    }
  }

  h2 {
    font-size: 1.75rem;
    line-height: 2.2rem;
    margin: 10px;
  }
  h4 {
    font-size: 1.1rem;
    line-height: 1.5rem;
    margin: 10px;
  }

  .centered {
    border-radius: 7px;
    max-width: 424px;
  }

  .cancellation-policy {
    margin-top: 10px;
    font-size: 10px;
    color: _gray666_;
    text-align: left;
    font-style: italic;
  }

  .big-icon {
    margin-bottom: 30px;
  }

  .checkmark {
    width: 80px;
    height: 80px;
  }
  .checkmark-check {
    color: _tintColor_;
    transform-origin: 50% 50%;
    stroke-dasharray: 52;
    stroke-dashoffset: 52;
    animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
  }

  .left-panel {
    text-align: center;
    padding-bottom: 70px;
    flex: 1;

    .replaces-booking-info {
      &::before {
        content: ' ';
        background: _tintColor_;
        opacity: 0.15;
        border-radius: 5px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      color: black;
      width: 280px;
      position: relative;
      text-align: center;
      box-sizing: border-box;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      margin: 30px auto;
      padding: 10px;
    }
  }

  .divider {
    width: 1px;
    flex-grow: 0;
    border-left: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right-panel {
    box-sizing: border-box;
    flex: 1.4;

    form {
      padding: 30px 70px;
      padding-bottom: 0;
    }
  }

  h2 {
    font-size: 2.1rem;
    line-height: 3rem;
    white-space: pre-wrap;
  }
  h4 {
    font-size: 1.2rem;
    line-height: 1.7rem;
    font-weight: 400;
    white-space: pre-wrap;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='date'] {
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 10px;
    font-size: 1.1rem;
    border: none;
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    outline: none;
    &:focus {
      border-bottom: 1px solid _tintColor_;
    }
    &.invalid {
      border-bottom: 1px solid #d85454;
    }
  }

  .checkbox-container {
    margin-bottom: 12px;
    margin-top: 0;
  }

  input[type='checkbox'] {
    margin-left: 0;
  }

  .TokenizedInput {
    width: 100%;
  }
  select {
    width: 100%;
    box-sizing: border-box;
  }
  label {
    color: _gray666_;
    text-align: left;
    &.invalid {
      color: #d85454;
    }
  }
  .Button {
    width: 200px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    display: block;
  }
  .email {
    color: gray;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
