.RemindersView {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .reminder-edit-form {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .reminders {
    padding: 15px 0;
    .reminder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
    }
  }
}

body.is-touch .RemindersView {
  .Button {
    height: 44px;
  }
}
body.is-mobile .RemindersView {
  display: flex;
  flex-direction: column;
  flex: 1;
  .reminder-edit-form {
    flex-direction: column;
  }
}

.Modal {
  label {
    display: block;
    margin-bottom: 5px;
  }
}
