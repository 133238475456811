$padding-horizontal: 30px;
$padding-vertical: 15px;

.PageDetailView {
  .footer {
    .delete-page-link,
    .view-page-link {
      display: inline-flex;
      align-items: center;
      white-space: pre;
      text-decoration: none;
      color: _tintColor_;
      padding: 10px 0;
      margin-right: 30px;
      cursor: pointer;
    }
  }

  .save {
    margin-left: 20px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .save.no-changes {
    background: _sidebarColor_;
    border: 1px solid transparent;
    color: _gray666_;
  }

  .main {
    display: flex;
    flex-direction: column;
    padding: $padding-vertical * 0.9 $padding-horizontal * 1.25;
    flex: 1;
    overflow-y: scroll;
    max-height: 100%;
    position: relative;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    background-color: _sidebarColor_;
    font-size: 1.1rem;
    width: 190px;

    a {
      display: flex;
      background-color: _sidebarColor_;
      align-items: center;
      text-decoration: none;
      color: _black_;
      height: 49px;
      padding-left: $padding-horizontal;
      padding-right: 5px;
      svg {
        padding-right: 10px;
      }
      &.active,
      &.active:hover {
        background-color: _tintColor_;
        color: white;
      }
      &:hover {
        background-color: _sidebarColorHover_;
      }
    }
  }
}

body.is-touch .PageDetailView {
  .Button {
    height: 44px;
    white-space: nowrap;
  }
}

body.is-mobile .PageDetailView {
  .footer {
    height: 50px;
    a.delete-page-link,
    a.view-page-link {
      margin-right: 15px;
    }
  }
  .sidebar {
    flex-direction: row;
    background-color: transparent;
    border-bottom: 1px solid #cecece;
    overflow-x: auto;
    overflow-y: visible;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: 36px;
    padding: 0px;
    &::-webkit-scrollbar {
      display: none;
    }
    a {
      background-color: transparent;
      padding: 0px 10px;
      color: #6a7285;
      text-decoration: none;
      height: 100%;
      white-space: nowrap;
      svg {
        display: none;
      }
      &.active,
      &.active:hover {
        background-color: transparent;
        color: _tintColor_;
        text-decoration: none;
        border-bottom: 2px solid _tintColor_;
      }
      &:hover {
        background-color: transparent;
        color: _sidebarColorHover_;
      }
    }
    a:first-child {
      padding-left: 20px;
    }
  }
}
