$padding-horizontal: 30px;
$padding-vertical: 15px;

.EmbedPageContainer {
  display: flex;
  flex-direction: column;
  background-color: _backgroundColor_;
  color: _black_;
  width: 100%;
  height: 100%;

  .header,
  .footer {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $padding-horizontal;
    flex-shrink: 0;
  }

  .header {
    font-size: 1.5rem;
    border-bottom: 1px solid #cecece;

    .breadcrumbs {
      a {
        color: _gray999_;
        text-decoration: none;
        &:hover {
          color: #777;
        }
      }
      svg {
        color: _gray999_;
      }
    }
  }

  .header input {
    font-size: 1.5rem;
    margin-top: 1px;
    margin-left: -1px;
    background-color: transparent;
    border-bottom: 1px solid white;
    border-left: none;
    border-top: none;
    border-right: none;
    color: #fff;
  }
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: _gray999_;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: _gray999_;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: _gray999_;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: _gray999_;
  }

  .header input:focus {
    outline: none;
  }

  .edit-page-name-icon {
    margin-left: 10px;
    display: none;
  }

  .header:hover .edit-page-name-icon {
    display: inline;
  }

  .done {
    margin-left: 20px;
    min-width: 100px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .done.no-changes {
    background: _sidebarColor_;
    border: 1px solid transparent;
    color: _gray666_;
  }

  .footer {
    justify-content: flex-end;
    border-top: 1px solid #cecece;

    .Button {
      align-self: center;
    }
  }

  .close-x {
    margin-right: -5px;
    color: _gray666_;
    user-select: none;
    &:active {
      color: #333;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    min-height: 0;
    flex: 1;
  }
}

body.is-mobile .EmbedPageContainer {
  .header,
  .footer {
    height: 40px;
    padding: 0 20px;
    border-bottom: none;
  }

  .content {
    flex-direction: column;
  }
}
