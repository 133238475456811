.TimezoneSelectView {
  align-self: flex-start;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 100%);
}
.TimezoneSelectView,
.LanguageSelectView {
  text-align: left;
  cursor: default;
  position: relative;
  border: 1px solid _grayCCC_;
  border-radius: 3px;
  cursor: default;
  position: relative;
  text-align: left;

  .item {
    align-items: center;
    display: flex;
    height: 32px;
    padding-left: 8px;
    padding-right: 13px;
    user-select: none;
    svg {
      flex-shrink: 0;
      margin-right: 4px;
    }
    .text {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:hover {
    .item {
      background: rgba(0, 0, 0, 0.05);
    }
  }

  .menu {
    background: _backgroundColor_;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    width: 250px;
    z-index: 5;

    .search {
      padding: 5px;
      position: relative;
      input {
        box-sizing: border-box;
        padding: 2px;
        width: 100%;
      }
    }

    ul {
      height: 180px;
      margin: 0;
      padding: 0;
      overflow-y: scroll;

      .divider {
        background-color: #aaa;
        height: 1px;
        margin: 5px;
      }

      li {
        list-style-type: none;
        padding: 4px 6px;

        .offset {
          float: right;
          color: #aaa;
        }

        &.keyfocus {
          background: rgba(0, 0, 0, 0.05);
        }
        &.active {
          background: _tintColor_;
          color: white;
        }
      }
    }
  }

  @media (max-width: 700px) {
    .item {
      font-size: 1rem;
      height: 44px;
      justify-content: center;
    }
  }
}
