.Button {
  color: white;
  padding: 0 15px;
  align-self: flex-start;
  display: inline-block;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 1.2rem;
  text-align: center;
  position: relative;
  user-select: none;
  cursor: pointer;

  &:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
  }

  &.normal {
    height: 31px;
    line-height: 31px;
  }

  &.small {
    height: 25px;
    line-height: 22px;
    font-size: 1.1rem;
  }

  &.cancel {
    background-color: _backgroundColor_;
    color: gray;
    &:hover {
      background-color: #cdcdcd;
    }
  }

  &.default,
  &.primary {
    background-color: _tintColor_;
    &:hover {
      &:after {
        opacity: 0.3;
      }
    }
    &:active {
      &:after {
        opacity: 0.6;
      }
    }
  }

  &.outlined {
    background-color: transparent;
    border: 1px solid _tintColor_;
    color: _tintColor_;
  }

  &.disabled {
    &:after {
      background: rgba(230, 230, 230, 0.6);
      opacity: 0.6;
    }
    &:hover,
    &:active {
      &:after {
        opacity: 0.6;
      }
    }
  }
}
