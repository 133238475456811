.BoxSelectView {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  button {
    width: 28px;
    height: 28px;
    color: _gray999_;
    line-height: 27px;
    text-align: center;
    border: 1px solid _grayCCC_;
    border-left: none;
    border-right: 1px solid _grayEEE_;
    background: transparent;
    font-size: 1rem;
    padding: 0;
    cursor: pointer;
    user-select: none;
    &:last-child {
      border-right: 1px solid _grayCCC_;
    }
    &:first-child {
      border-left: 1px solid _grayCCC_;
    }

    &:hover {
      background-color: _grayEEE_;
    }
    &.active {
      border-bottom: 3px solid _tintColor_;
      color: _black_;
      &:hover {
        background-color: _grayEEE_;
      }
    }
  }
  &.disabled {
    button {
      cursor: default;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;
      &:last-child {
        border-right: 0;
      }
      &.active {
        border-bottom: 3px solid _grayCCC_;
      }
      &:hover {
        background-color: transparent;
      }
    }
  }
}

body.is-touch .BoxSelectView {
  justify-content: space-between;
  width: 100%;
  button {
    width: 38px;
    height: 38px;
    border-radius: 38px;
    border: none;
    font-size: 1.2rem;
    &:last-child {
      border: none;
    }
    &:first-child {
      border: none;
    }

    &.active {
      border: 1px solid _tintColor_;
      color: _tintColor_;
      &:hover {
        background-color: _tintColor_;
        color: white;
      }
    }
  }
  &.disabled {
    button {
      &.active {
        border: none;
      }
    }
  }
}
