.PagesGridView {
  max-width: 1335px;

  .content {
    display: block;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content max-content;
    padding: 20px;
    overflow-y: auto;
  }

  .PageItem {
    width: 340px;
    vertical-align: top;
    user-select: none;
    position: relative;
    margin: 10px;
    box-sizing: border-box;
    background-color: _sidebarColor_;
    border: 1px solid _grayEEE_;
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    padding: 20px;

    .name {
      font-size: 1.2em;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .location,
    .slug {
      font-size: 1em;
      margin-bottom: 5px;
      opacity: 0.7;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-shrink: 0;
    }
    .slug {
      margin-bottom: 15px;
    }

    .linkContainer {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -10px;

      .link {
        display: flex;
        color: _tintColor_;
        text-decoration: none;
        align-items: center;
        margin-right: 10px;
        padding: 4px 6px;
        border-radius: 2px;
        &:hover {
          background-color: _sidebarColorHover_;
        }
        .link-text {
          display: inline-block;
          white-space: nowrap;
        }
      }
    }
  }

  .CreatePageItem {
    color: _gray999_;
    border: 2px dashed _sidebarColor_;
    border-radius: 3px;
    background-color: transparent;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-decoration: none;
    min-height: 150px;
    &:hover {
      color: _gray999_;
      border: 2px dashed _grayEEE_;
      background-color: _sidebarColor_;
    }
  }
}

body.is-mobile .PagesGridView {
  max-width: 100%;

  .PageItem {
    max-width: 100%;
    width: 100%;
    margin: 10px 0px;
  }
  .content {
    grid-template-columns: 1fr;
  }
}
