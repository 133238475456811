/* csslint ignore:start */
body,
html {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: 'flex';
  width: 100vw;
  height: 100vh;
  font-size: 10pt;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h2 {
  font-size: 1.2rem;
  font-weight: 400;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes sheetin {
  0% {
    opacity: 0.4;
    left: 20px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@media (max-width: 700px) {
  h2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
