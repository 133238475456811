$borderDark: #d6d6d6;

.CalendarWeekView {
  display: flex;
  flex-direction: column;
  max-height: 700px;
  user-select: none;
  opacity: 0;

  canvas {
    visibility: hidden;
    position: absolute;
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  .CalendarNavHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $borderDark;
    align-items: center;
    padding: 5px 0;
    color: _tintColor_;
    .title {
      font-size: 1.4rem;
    }
    .side-items {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 150px;
    }
    .Button {
      width: 50px;
      height: 1.6rem;
      margin: 5px 10px;
      font-size: 0.9rem;
      line-height: 1.6rem;
    }
    .SVGButton {
      padding: 5px 15px;
    }
  }

  .weekHeader {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    .timeColumnSpacer {
      width: 100px;
    }

    .week {
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      border-left: 1px solid $borderDark;
    }

    .weekContents {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .currentDay {
      color: _tintColor_;
      border-radius: 44px;
      width: 44px;
      height: 44px;
    }
  }

  .calendar {
    display: flex;
    flex: 1;
    overflow: hidden;
    position: relative;
    border-top: 1px solid $borderDark;

    .outerCalendarContent {
      flex: 1;
      overflow-y: scroll;
      flex-direction: column;
      position: relative;
    }

    .calendarRibbon {
      min-height: 25px;
      height: 25px;
      margin-left: 100px;
      border-left: 1px solid #ddd;
      background: repeating-linear-gradient(-65deg, #ddd 0px, transparent 1.3px, transparent 12px) repeat-x;

      &:last-child {
        border-top: 1px solid #ddd;
      }
    }

    .calendarContent {
      display: flex;
      flex-direction: row;
      position: relative;
    }
    .calendarBackground {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-repeat: repeat;
    }

    .slot {
      color: _tintColor_;
      padding-top: 6px;
      padding-left: 9px;
      border: 0;
      border-top: 4px solid _tintColor_;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      background: white;
      box-sizing: border-box;
      cursor: pointer;
      transition: transform 100ms linear, box-shadow 100ms linear;
      text-align: left;
      width: 100%;

      &:hover {
        z-index: 100 !important;
        transform: scale(1.1, 1.1);
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.25);
      }
    }

    .endSlot {
      color: _tintColor_;
      padding-top: 6px;
      padding-left: 9px;
      border-bottom: 4px solid _tintColor_;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      background-color: white;
      box-sizing: border-box;

      &:hover {
        background: _grayEEE_;
      }
    }
    .dayColumn {
      flex: 1;
      border-right: 1px solid $borderDark;
      position: relative;
      &:last-child {
        border-right: none;
      }
    }

    .currentDayColumn {
      background-color: rgba(0, 0, 0, 0.02);
    }

    .timeColumn {
      position: relative;
      width: 100px;
      border-right: 1px solid $borderDark;
      background-color: white;
    }

    .no-slots-available {
      background-color: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

.slot-duration-15 {
  .CalendarWeekView {
    .calendar .slot {
      padding-top: 3px;
      font-size: 12px;
      border-top-width: 3px;
    }
  }
}
