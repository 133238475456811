.WidgetView {
  .companyFormsContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .styleFormsContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
  }

  .formFields {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }
  }

  .colorSwatch {
    border: 1px solid _black_;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
  }

  .invalidColorSwatch {
    border: 1px dashed red;
  }

  .widgetField {
    width: 200px;
  }

  body.is-touch {
    .Button {
      height: 44px;
    }
  }
}

body.is-mobile .WidgetView {
  display: flex;
  flex-direction: column;
  flex: 1;

  .companyFormsContainer {
    flex-direction: column;
  }

  .styleFormsContainer {
    flex-direction: column;
  }

  .widgetField {
    width: 100%;
  }

  h2 {
    font-size: 1.1rem;
  }
}
