.FormattedError {
  display: inline-flex;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 1.5rem;
  max-width: 300px;
  text-align: center;
  align-items: center;
  color: #4169E1;
}