// Global / Default Form Control Styling

input[type='text'],
input[type='email'],
input[type='tel'],
input[type='date'],
input[type='number'] {
  border: 0;
  color: _black_;
  border-bottom: 1px solid _grayCCC_;
  background: _backgroundColor_;
  font-size: 15px;
  font-weight: 400;
  padding: 6px 0;
  outline: none;
  &:focus {
    border-bottom: 1px solid _tintColor_;
  }
  &.invalid {
    border-bottom: 1px solid #d85454;
  }
}

input[type='text']:disabled,
input[type='email']:disabled,
input[type='tel']:disabled,
input[type='number']:disabled {
  background: _grayEEE_;
  opacity: 0.7;
  padding: 6px;
}

textarea {
  padding: 8px;
  color: _black_;
  background: _backgroundColor_;
  border: 1px solid _grayCCC_;
  box-sizing: border-box;
}

select {
  color: _black_;
  border: 1px solid _grayCCC_;
  border-radius: 3px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.1) 100%);
  padding: 5px 5px;
  font-size: 15px;
  font-weight: 400;
  height: 30px;

  & [disabled] {
    color: _gray999_;
  }
  &:invalid,
  & option:disabled {
    color: _gray999_;
  }
  &.invalid {
    border: 1px solid #d85454;
  }
}
select:disabled {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.14) 100%);
  opacity: 0.7;
  padding: 6px;
}

label {
  color: _gray666_;
  &.invalid {
    color: #d85454;
  }
}

.FormBlock {
  flex: 1;
  margin-bottom: 30px;
  margin-right: 30px;
  min-width: 200px;
  label {
    color: _black_;
  }
  &.big-label {
    input[type='text'],
    input[type='email'],
    input[type='tel'],
    input[type='date'],
    select {
      color: _gray666_;
    }
    option {
      background: initial;
      color: black;
    }
  }
}

.checkbox-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Create a custom checkbox */
input[type='checkbox'] {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  position: relative;
  flex-shrink: 0;
  border: 2px solid _tintColor_;
  cursor: pointer;
  &:hover {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  &:checked {
    background-color: _tintColor_;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  &::after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 0px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* Show the checkmark when checked */
  &:checked::after {
    display: block;
  }
}
input[type='checkbox']:disabled {
  border: 2px solid _grayCCC_;
  &:checked {
    background-color: _grayCCC_;
  }
}

body.is-mobile {
  .FormBlock {
    margin-right: 0px;
    &.big-label {
      input {
        width: 100%;
        font-size: 1.1rem;
      }
    }
  }

  .checkbox-container {
    font-size: 1.1rem;
  }
}
