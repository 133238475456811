.MultiSelectValuesPicker {
  .addMultiSelectOption {
    display: flex;
    flex-direction: row;
  }

  .multiSelectOption {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 0;
    max-width: 190px;
  }
}
