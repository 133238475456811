@keyframes alertFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.alert-background {
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation-name: alertFadeIn;
  animation-duration: 400ms;
}

.alert {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  min-width: 280px;
  border: 1px solid _gray999_;
  border-radius: 8px;
  background-color: _backgroundColor_;
  line-height: 1.4rem;
  color: _black_;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  animation-name: alertFadeIn;
  animation-duration: 400ms;
}

.alert-padded {
  padding: 15px;

}

.alert-actions {
  display: flex;
  border-top: 1px solid _grayCCC_;
  justify-content: flex-end;
}
