.Tooltip {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  color: _gray666_;
  padding-left: 5px;
  div {
    position: absolute;
    z-index: 3;
    width: 40vw;
    max-width: 200px;
    word-wrap: initial;
    white-space: initial;
    background: _backgroundColor_;
    border: 1px solid _grayEEE_;
    padding: 5px 8px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    color: _black_;
    font-size: 0.9rem;
    @media (max-width: 360px) {
      width: 30vw;
      max-width: 160px;
    }
  }
  &.right div {
    right: 10px;
  }
  &.left div {
    left: 15px;
  }
}
