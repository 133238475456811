.OpeningHoursView {
  display: flex;
  flex-direction: column;

  .OpeningHoursPicker {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 3px;

    .pickers-container {
      flex-direction: row;
      align-items: center;
      display: flex;
    }

    .Button {
      min-width: 36px;
      margin-left: 15px;
    }
    .TimePickerView {
      margin-right: 10px;
      margin-left: 10px;
    }

    .until {
      color: _gray666_;
      font-size: 10pt;
    }
  }
}
body {
  input[type=text].rc-time-picker-input {
    padding-left: 8px;
  }
  .rc-time-picker-panel-inner {
    margin-top: -3px;
  }
  .rc-time-picker-panel-select,
  .rc-time-picker-panel-input {
    font-size: 15px;
  }
}

body.is-mobile .OpeningHoursView {
  p {
    font-size: 1.1rem;
  }
  .OpeningHoursPicker {
    flex-direction: column;
    margin-bottom: 20px;
    .pickers-container {
      width: 100%;
      margin-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid _grayCCC_;
    }
  }
}
