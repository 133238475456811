.PageWizardEventInfoView {
  min-width: 0; /* Fixes issue where page was extending outside bounds of its container. */
  width: 100%;
  margin-bottom: 20px;

  .eventInfoCollapsed {
    border: 1px solid _grayCCC_;
    padding: 10px;
  }

  label {
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 100%;
    max-width: 480px;

    &[name='attendee_capacity'] {
      // for placeholder "capacity" + 2 padding
      width: 10ch;
    }
  }

  .formErrors {
    color: red;
  }

  .textInputContainer {
    max-width: 100%;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }

  .timeFieldSection {
    display: flex;
    flex-wrap: wrap;

    & > div {
      margin-top: 25px;
      margin-right: 10px;
    }
  }

  .timeField {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    label {
      white-space: nowrap;
    }
  }

  .timeFieldSelectContainer {
    display: flex;
    flex-shrink: 0;
  }

  .calendar-preview {
    width: 250px;
    margin-left: 50px;
    margin-right: -37px;
    margin-top: -20px;
    margin-bottom: -20px;
    height: 0;
    position: relative;

    .preview {
      font-size: 11px;
      color: _gray666_;
      padding: 5px;
    }

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 380px;
      background: _sidebarColor_;
      border-left: 1px solid _grayCCC_;
      opacity: 0.5;
    }

    .calendar-event {
      border-left: 4px solid #8bd65a;
      position: absolute;
      left: 15px;
      top: 51px;
      width: 205px;
      padding: 5px;
      background: #e5f6db;
      color: #698847;
    }
    .calendar-time-line {
      width: 100%;
      position: absolute;
      border-top: 1px solid _black_;
      opacity: 0.075;
      &.dark {
        border-top: 1px solid _black_;
        opacity: 0.15;
      }
    }
  }
}
