.CalendarsView {
  max-width: 100%;
  background-color: _backgroundColor_;
  .message-wrap {
    color: _gray666_;
  }
}
body.is-mobile .CalendarsView {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 1.1rem;
}
