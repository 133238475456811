

.alert-box {
    display: grid;
    grid-template: "body close";
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 16px;
    margin: 1rem 0;
        
    &.error {
        /* color-red-lighter */
        background: #FFEEF0;
        border: 1px solid #FB8693;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &.warning {
         /* color-red-lighter */
         background: #FECA7C40;
         border: 1px solid #FECA7C;
         box-sizing: border-box;
         border-radius: 4px;
    }

    .x-close {
        grid-area: close;
    }

    .alert-body {
        display: grid;
        grid-auto-columns: auto 1fr;
        grid-auto-flow: column;
        gap: 1rem;
        grid-area: body;

        p {
            font-weight: 600;
            padding: 0;
            margin: 0;
        }

        ul {
            margin: 0;
            padding-left: 24px;
        }
    }
}