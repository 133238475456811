.MainViewError {
  color: _black_;
  padding: 70px;
  text-align: center;
}


.loading-wrap {
  display: grid;
  height: 100%;
}