.RadioGroup {
  font-size: 1rem;

  .RadioButton {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 18px;
    cursor: pointer;
    &.small {
      margin-bottom: 3px;
    }
    label {
      padding-left: 7px;
      line-height: 1.5em;
      cursor: pointer;
      .description {
        opacity: 0.6;
        a {
          color: _black_;
        }
      }
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    position: relative;
    height: 16px;
    width: 16px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 2px solid _tintColor_;
    background: transparent;
    cursor: pointer;

    &:checked {
      background: _tintColor_;
    }
  }
}
